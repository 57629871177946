import {tns} from '../../../../../node_modules/tiny-slider/src/tiny-slider.js';
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

if (document.querySelector(".tiny-slider")) {
  tns({
    container: '.tiny-slider',
    items: 3,
    loop: false,
    slideBy: 'page',
    autoplay: false,
    controlsText: ["<span class='fontello icon-angle-left'></span>","<span class='fontello icon-angle-right'></span>"],
    responsive: {
      768: {
        items: 6
      }
    }
  });
}
